<!-- src/components/QrCodeGenerator.vue -->
<template>
  <div class="container mt-4">
    <div class="input-group mb-3">
      <input v-model="text" class="form-control" placeholder="Inserisci qui la stringa da generare" />
      <div class="input-group-append">
        <button @click="generateQrCode" class="btn btn-primary">Genera codice QR</button>
      </div>
    </div>
    <div>
      <canvas ref="canvas" height="500" width="500"></canvas>
    </div>
  </div>
</template>

<script>
  import QRCode from 'qrcode';

  export default {
    data() {
      return {
        text: '',
        size: 500,
        foregroundColor: '#000000', 
        backgroundColor: '#ffffff'
      };
    },
    methods: {
      generateQrCode() {
        const options = {
          width: this.size,
          height: this.size,
          color: {
            dark: this.foregroundColor, // Colore di primo piano
            light: this.backgroundColor  // Colore di sfondo
          }
        };
        QRCode.toCanvas(this.$refs.canvas, this.text, options, function (error) {
          if (error) console.error(error);
          console.log('QR code generated!');
        });
      }
    }
  };
</script>
